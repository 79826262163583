import FingerprintJS from '@fingerprintjs/fingerprintjs';

class Systema {

    constructor() {
        this.version = "2.11.0";
        this.logger = new Logger();
        this.SuggestMode = "None";
        this.url = window.location.href.split("?")[0];
        this.monitorEnabled = false;
        // Open source version of FingerprintJS. See https://github.com/fingerprintjs/fingerprintjs
        this.fpPromise = FingerprintJS.load()
    }

    async run(config) {
        // Open source version of FingerprintJS. See https://github.com/fingerprintjs/fingerprintjs
        this.fpPromise = await FingerprintJS.load()
        return new Promise(async (resolve, reject) => {
            try {
                let retValue = await this._configure(config);
                if (document.readyState !== 'loading') {
                    this._monitor();
                } else {
                    window.addEventListener('DOMContentLoaded', () => {
                        if (!this.monitorEnabled) {
                            this.monitorEnabled = true;
                            this._monitor();
                        }
                    });
                }
                window.dispatchEvent(new CustomEvent("SystemaStartupComplete", { sid: retValue.sid, fid: retValue.fid }));
                resolve({ sid: retValue.sid, fid: retValue.fid });
            } catch (error) {
                let message = "The following errors occured during configuration: " + error;
                this.logger.warn(message);
                return reject(message);
            }
        })
    }

    setUserName(userName) {
        this._setClientData({ "user_name": userName.trim() });
    }

    clearUserName() {
        this._setClientData({ "user_name": "" });
    }

    getApiData() {
        let data = this._getClientData();
        if (data) {
            return { user_id: { sid: data["sid"], fid: window.localStorage.getItem("systema-fid"), uid: data["user_name"] || "" }, environment: data["environment"], endpoints: { search: data["search_base_url"], recommend: data["recommend_base_url"] } };
        } else {
            return undefined;
        }
    }

    getSID() {
        let data = this._getClientData();
        if (data) {
            return data["sid"];
        } else {
            return undefined;
        }
    }

    getFID() {
        return window.localStorage.getItem("systema-fid");
    }

    getUID() {
        let data = this._getClientData();
        if (data) {
            return data["user_name"];
        } else {
            return "";
        }

    }

    getEnv() {
        let data = this._getClientData();
        if (data) {
            return data["environment"];
        } else {
            return undefined;
        }
    }

    trackPageView() {
        try {
            this._sendMessage("PageView");
        } catch (error) {
            this.logger.error(error);
        }
    }

    trackEdmClicked() {
        try {
            // check for systema_edm_uid
            if (window.location.search) {
                let urlParams = new URLSearchParams(window.location.search);
                if (urlParams.get('systema_edm_uid')) {
                    let clientData = this._getClientData()
                    clientData['systema_edm_uid'] = urlParams.get('systema_edm_uid')

                    // only trigger EdmClicked if it is a new product or new edm_uid
                    let pid = this._getProductId()
                    if (!clientData['last_systema_edm_pid'] ||
                        !clientData['last_systema_edm_uid'] ||
                        clientData['last_systema_edm_pid'] != pid ||
                        clientData['last_systema_edm_uid'] != clientData['systema_edm_uid']
                        ) {
                        clientData['user_name'] = clientData['systema_edm_uid']
                        clientData['last_systema_edm_uid'] = clientData['systema_edm_uid']
                        clientData['last_systema_edm_pid'] = pid
                        this._setClientData(clientData) // store last_systema_edm_pid  systema_edm_uid and user_name

                        // extract UTM tracking data if exists
                        let metadata = [
                            {
                                name: "Utm",
                                value: JSON.stringify({
                                    'widget_id': urlParams.get('widget_id'),
                                    'utm_source': urlParams.get('utm_source'),
                                    'utm_medium': urlParams.get('utm_medium'),
                                    'utm_campaign': urlParams.get('utm_campaign'),
                                    'utm_id': urlParams.get('utm_id')
                                })
                            }
                        ];
                        this._sendMessage("EdmClicked", metadata)  // this will use uid = systema_edm_uid and all other subsequent events will have the same uid
                    }
                }
            }

        } catch (error) {
            this.logger.error(error);
        }
    }

    trackItemClicked(clicked) {
        try {
            let item = clicked;
            while (!item.getAttribute("data-systema-rec-id")) {
                item = item.parentElement
            }

            let data = [
                { name: "RecId", value: item.getAttribute("data-systema-rec-id") },
                { name: "Referer", value: window.location.href }
            ];

            this._sendMessage("ItemClicked", data);
        } catch (error) {
            this.logger.error(error);
        }
    }

    trackContentClicked(content) {
        try {
            let recId = content.getAttribute("data-systema-content-rec-id");
            if (!recId) {
                this.logger.error('content-rec-id is missing');
                return;
            }

            let campaignId = content.getAttribute("data-systema-content-campaign-id");
            if (!campaignId) {
                this.logger.error('content-campaign-id is missing');
                return;
            }

            let data = [
                { name: "CampaignId", value: campaignId},
                { name: "RecId", value: recId},
                { name: "Referer", value: window.location.href }
            ];

            this._sendMessage("ContentClicked", data);
        } catch (error) {
            this.logger.error(error);
        }
    }

    trackContentShown(container) {
        try {
            let contentIds = [];
            let items = container.querySelectorAll('[data-systema-content-rec-id]:not([systema-content-counted])')
            for (let item of items) {
                contentIds.push({
                    'RecId': item.getAttribute("data-systema-content-rec-id"),
                    'CampaignId': item.getAttribute("data-systema-content-campaign-id")
                });

                item.addEventListener('click', () => { window.Systema.trackContentClicked(item) }, true);
                item.setAttribute("systema-content-counted", "true");
            }

            // Everything shown in the Container has been sent
            if (contentIds.length <= 0) {
                return;
            }


            let data = {
                name: "Containers",
                value: [{
                    Contents: contentIds,
                    ResultId: container.getAttribute("data-systema-content-result-id")
                }]
            }

            this._sendMessage("ContentShown", [data]);

        } catch (error) {
            this.logger.error(error);
        }
    }


    trackContainerShown(container) {
        try {
            let ids = [];
            let items = container.querySelectorAll('[data-systema-rec-id]:not([systema-link-counted])')
            for (let item of items) {
                ids.push(item.getAttribute("data-systema-rec-id"));
                item.addEventListener('click', () => { window.Systema.trackItemClicked(item) }, true);
                item.setAttribute("systema-link-counted", "true");
            }

            // Everything shown in the Container has been sent
            if (ids.length <= 0) {
                return;
            }


            let data = {
                name: "Containers",
                value: [{
                    Products: [...new Set(ids)].map((x) => { return { recId: x } }),
                    ResultId: container.getAttribute("data-systema-result-id")
                }]
            }

            this._sendMessage("ContainerShown", [data]);

        } catch (error) {
            this.logger.error(error);
        }
    }

    trackItemAcquired(items) {
        try {
            var itemArray = [];
            if (Array.isArray(items)) {
                itemArray = items;
            } else {
                itemArray.push(items);
            }

            window.Systema._sendMessage("AddToCart", [{ name: "Items", value: itemArray }]);
        } catch (error) {
            this.logger.error(error);
        }
    }

    trackItemRelinquished(items) {
        try {
            var itemArray = [];
            if (Array.isArray(items)) {
                itemArray = items;
            } else {
                itemArray.push(items);
            }

            window.Systema._sendMessage("RemoveFromCart", [{ name: "Items", value: itemArray }]);
        } catch (error) {
            this.logger.error(error);
        }
    }

    trackAcquisitionComplete(order) {
        try {
            window.Systema._sendMessage("Purchase", [{ name: "Order", value: order }]);
        } catch (error) {
            this.logger.error(error);
        }
    }

    trackAddToWishlist(items) {
        try {
            var itemArray = [];
            if (Array.isArray(items)) {
                itemArray = items;
            } else {
                itemArray.push(items);
            }
            window.Systema._sendMessage("AddToWishlist", [{ name: "Items", value: itemArray }]);
        } catch (error) {
            this.logger.error(error);
        }
    }

    trackRemoveFromWishlist(item) {
        try {
            window.Systema._sendMessage("RemoveFromWishlist", [{ name: "Item", value: item }]);
        } catch (error) {
            this.logger.error(error);
        }
    }

    moveCarouselToPage(itemClicked, pageNum) {
        let carousel = this.findParentCarousel(itemClicked);

        if (carousel == null) {
            this.logger.error("Failed to locate Placeholder to change page");
            return;
        }

        let size = carousel.getAttribute('data-systema-page-size');
        if (size) {
            carousel.setAttribute("data-systema-param-start", size * (pageNum - 1));
            console.log(pageNum);
        } else {
            this.logger.error(error);
        }

        this._renderCarousel(carousel);
        carousel.parentElement.scrollIntoView(true);
    }

    setCarouselSort(sortItem) {
        let carousel = this.findParentCarousel(sortItem);

        if (carousel == null) {
            this.logger.error("Failed to locate Placeholder to set sort order");
            return;
        }

        this.resetCarouselPagination(carousel);
        carousel.setAttribute('data-systema-param-score', sortItem.value);
        this._renderCarousel(carousel);
        carousel.parentElement.scrollIntoView(true);
    }

    changeCarousel(trigger, carouselId) {
        let carousel = this.findParentCarousel(trigger);

        if (carousel == null) {
            this.logger.error("Failed to locate Placeholder to set sort order");
            return;
        }

        this.resetCarouselPagination(carousel);
        carousel.setAttribute("data-systema-placeholder", carouselId);
        this._renderCarousel(carousel);
        carousel.parentElement.scrollIntoView(true);
    }

    findParentCarousel(rootItem) {
        let parent = rootItem.parentElement;

        while (parent) {
            let placeholder = parent.getAttribute("data-systema-placeholder");
            if (placeholder) {
                break;
            }

            if (parent.tagName == "body") {
                parent = null;
                break;
            }

            parent = parent.parentElement;
        }

        return parent;
    }

    resetCarouselPagination(carousel) {
        carousel.removeAttribute('data-systema-page-size');
        carousel.removeAttribute('data-systema-param-pagination_timestamp');
        carousel.setAttribute('data-systema-param-start', '0');
    }

    /**
     * Following are Private Methods intended to be used internally by the Systema Library only.
     */

    async _configure(config) {
        try {
            if (config["log_level"]) {
                let level = this.logger.level[config["log_level"]];
                if (level) {
                    this.logger.log_level = level;
                }
            }
        } catch { }

        this.logger.debug("Configuring Systema Library");
        let mandatory = [];

        if (!config["client_key"]) {
            mandatory.push('client_key - must be supplied');
        }

        if (!config["environment"]) {
            mandatory.push("environment - not supplied");
        } else {
            config["environment"] = config["environment"].replace("production", "prod");
            config["environment"] = config["environment"].replace("testing", "test");
            config["environment"] = config["environment"].replace("development", "dev");

            if (config["environment"] != "test" && config["environment"] != "prod" && config["environment"] != "dev") {
                mandatory.push("environment - Unsupported value");
            }
        }

        this.storageKey = `__sys-${config["environment"]}_${config.client_key}`;

        if (config["product_id_selector"]) {
            this.product_id_selector = config["product_id_selector"];
        }

        if (config["product_id_attribute"]) {
            this.product_id_attribute = config["product_id_attribute"];
        }

        if (!config.tracker_base_url) {
            config.tracker_base_url = `https://tracker.${config.client_key}.${config.environment}.systema.cloud`;
        }

        if (!config.search_base_url) {
            config.search_base_url = `https://search.${config.client_key}.${config.environment}.systema.cloud`;
        }

        if (!config.recommend_base_url) {
            config.recommend_base_url = `https://recommend.${config.client_key}.${config.environment}.systema.cloud`;
        }

        if (!config.suggest_ui_widget_selector) {
            config.suggest_ui_widget_selector = "[data-systema-suggest-placeholder]:not([data-systema-observed])";
        }
        if (config["tracker_base_url"].endsWith('/')) {
            config["tracker_base_url"] = config["tracker_base_url"].slice(0, -1)
        }

        if (config["search_base_url"].endsWith('/')) {
            config["search_base_url"] = config["search_base_url"].slice(0, -1)
        }

        if (config["recommend_base_url"].endsWith('/')) {
            config["recommend_base_url"] = config["recommend_base_url"].slice(0, -1)
        }

        if (mandatory.length > 0) {
            throw new Error(mandatory.join());
        }

        let fid = localStorage.getItem('systema-fid') || undefined;
        if (!fid) {
            let fidObj = await this._generateFID();
            fid = fidObj.fid
            localStorage.setItem('systema-fid', fid);
            config["ipaddress"] = fidObj.ipaddress
            config["fingerprintfromjs"] = fidObj.fingerprintfromjs
        }

        let client = this._setClientData(config);

        this.logger.debug("Startup Complete");
        return { sid: client.sid, fid: fid }
    }

    _monitor() {
        this.trackEdmClicked()
        this.trackPageView();
        this._monitorContents();
        this._monitorContainers();
        this._setupSuggestions();
        this._setupCarousels();

        var body = document.querySelector("body");
        var bodyObserver = new MutationObserver(async (mutations) => {
            this.logger.debug("Total mutations:" + mutations.length)
            // On any DOM change
            this._monitorContents();
            this._monitorContainers();

            // If using React Router, the DOM contents will update but will not be reloaded.
            // This will ensure a new page view is sent.
            if (this.url != window.location.href.split("?")[0]) {
                this.url = window.location.href.split("?")[0];
                this.trackPageView();
            }

            // This will be  until the setup method finds the element.
            this._setupSuggestions();
        });

        bodyObserver.observe(body, { attributes: true, childList: true, characterData: false, subtree: true });
    }

    _setupCarousels() {
        var carousels = document.querySelectorAll('[data-systema-placeholder]:not([data-systema-observed])');
        carousels.forEach(async (carousel) => {
            this._renderCarousel(carousel);
        });
    }

    _monitorContents() {
        var recContainers = document.querySelectorAll('[data-systema-content-result-id]:not([data-systema-observed])');

        recContainers.forEach((container) => {
            this.monitorContent(container)
        }, this);
    }

    // Find the content elements and attach listeners
    // This is made public method so that for any new added content, this method can be called
    monitorContent(container) {
        container.setAttribute('data-systema-observed', true);

        var scrollObserver = new IntersectionObserver((entries) => {
            // If intersectionRatio is 0, the target is out of view so nothing to do
            if (entries[0].intersectionRatio <= 0) {
                container.setAttribute('data-systema-visible', false);
            }
            else {
                container.setAttribute('data-systema-visible', true);
            }
        });

        scrollObserver.observe(container);

        var changeObserver = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.attributeName === "data-systema-visible" && mutation.target.getAttribute("data-systema-visible") === "true") {
                    this.trackContentShown(mutation.target);
                }
            }, this);
        });

        changeObserver.observe(container, { attributes: true, childList: false, characterData: false, subtree: false });
    }


    _monitorContainers() {
        var recContainers = document.querySelectorAll('[data-systema-result-id]:not([data-systema-observed])');

        recContainers.forEach((container) => {
            this.monitorContainer(container)
        }, this);
    }

    // Find the content elements and attach listeners
    // This is made public method so that for any new added content, this method can be called
    monitorContainer(container) {
        container.setAttribute('data-systema-observed', true);

        var scrollObserver = new IntersectionObserver((entries) => {
            // If intersectionRatio is 0, the target is out of view so nothing to do
            if (entries[0].intersectionRatio <= 0) {
                container.setAttribute('data-systema-visible', false);
            }
            else {
                container.setAttribute('data-systema-visible', true);
            }
        });

        scrollObserver.observe(container);

        var changeObserver = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if ((mutation.attributeName === "data-systema-visible" || mutation.attributeName === "data-systema-result-id")
                    && mutation.target.getAttribute("data-systema-visible") === "true") {
                    this.trackContainerShown(mutation.target);
                }
            }, this);
        });

        changeObserver.observe(container, { attributes: true, childList: false, characterData: false, subtree: false });
    }

    _setupSuggestApi(suggestWidgetSelector, config) {
        let suggestWidget = document.querySelector(suggestWidgetSelector);

        // Ensure the widget is on the page;
        if (!suggestWidget || !(suggestWidget instanceof HTMLInputElement)) {
            return;
        }

        // Stop setup being called again
        this.SuggestMode = "API";

        suggestWidget.addEventListener('keydown', (event) => {
            this.logger.debug("Value: " + event.currentTarget.value);
            if (event.currentTarget.value.length > 0) {
                if (this.smartSearchTimeout) {
                    this.logger.debug("Timeout cleared");
                    clearTimeout(this.smartSearchTimeout)
                }

                this.smartSearchTimeout = setTimeout(() => {
                    this._getSuggestions(suggestWidget)
                }, (config["min_search_time"] || 400));
            }
        });

        fetch(`${config["search_base_url"]}/v1/suggest`, {
            method: "POST",
            headers: { "Content-Type": "text/plain" },
            body: JSON.stringify(payload)
        }).then(async (response) => {
            let result = await response.json();
            if (response.status != 200) {
                window.dispatchEvent(new CustomEvent("SystemaSearchResultError", { "detail": { code: response.status, message: `Invalid response: ${response.statusText}` } }));
            } else {
                this.logger.debug("Response from smart search " + result);
                window.dispatchEvent(new CustomEvent("SystemaSearchResult", { "detail": result }));
            }
        }).catch(function (error) {
            window.dispatchEvent(new CustomEvent("SystemaSearchResultError", { "detail": { code: 0, message: `Unknown Error: ${error.message}` } }));
        });
    }

    _setupSuggestUi(placeholderSelector) {
        let placeholder = document.querySelector(placeholderSelector);
        if (!placeholder) {
            return;
        }

        // Check if we should move the placeholder elsewhere?
        let replaceSelector = placeholder.getAttribute("data-systema-replace");
        if (replaceSelector) {
            let replaceElement = document.querySelector(replaceSelector);
            if (replaceElement) {
                // Where should we put the placeholder
                let replaceMode = placeholder.getAttribute("data-systema-replace-mode") || "self";
                switch (replaceMode) {
                    // Replace the item itself
                    case "self":
                        replaceElement.parentElement.replaceChild(placeholder, replaceElement);
                        break;
                    // Remove all children of the element and append the item
                    case "content":
                        while (replaceElement.firstChild) {
                            replaceElement.removeChild(replaceElement.firstChild);
                        }
                        replaceElement.appendChild(placeholder);
                        break;
                    // Append the placehold as a child of
                    case "append":
                        replaceElement.appendChild(placeholder);
                        break;
                }

            } else {
                this.logger.warn("Replace Element specified doesn't exist!");
            }
        }



        // Stop setup being called again
        this.SuggestMode = "UI";

        placeholder.style.display = "flex";

        let label = document.createElement("label");
        label.setAttribute("for", "search-input");
        label.textContent = "Search:";
        label.classList.add("systema-ac-search-label")
        placeholder.appendChild(label);

        let container = document.createElement("div");
        container.classList.add("systema-ac");
        placeholder.appendChild(container);

        let suggestWidget = document.createElement("input");
        suggestWidget.type = "text";
        suggestWidget.id = "search-input";
        suggestWidget.classList.add("systema-ac-search-input");
        container.appendChild(suggestWidget);

        let align = placeholder.attributes["data-systema-suggest-align"];
        if (align) {
            switch (align.value[0]) {
                // left/Left
                case 'l':
                case 'L':
                    placeholder.style["justify-content"] = "flex-start";
                    container.classList.add("systema-ac-left");
                    break;
                // centre/Center/middle/Middle
                case 'c':
                case 'C':
                case 'm':
                case 'M':
                    placeholder.style["justify-content"] = "center";
                    container.classList.add("systema-ac-center");
                    break;
                // right/Right
                case 'r':
                case 'R':
                    placeholder.style["justify-content"] = "flex-end";
                    container.classList.add("systema-ac-right");
                    break;
            }
        }

        //     <div id="systema-ac" class="systema-ac">
        //         <input id="search-input" type="text">
        //     </div>

        // triggers the search and handles up/down/enter behavior
        suggestWidget.addEventListener('keydown', (event) => {

            switch (event.keyCode) {
                case 40:
                    this._moveActiveSuggestItem(1)
                    return;
                case 39:
                    return;
                case 38:
                    this._moveActiveSuggestItem(-1);
                    return;
                case 37:
                    return;
                case 13:
                    let items = document.querySelectorAll(".systema-ac-item:not(.systema-ac-header)")
                    let currentItem = items[this.currentItemIndex];
                    if (currentItem) {
                        let a = currentItem.querySelector('a');
                        if (a) {
                            a.click();
                        } else {
                            currentItem.click();
                        }
                    }
                    return;
            }

            let data = this._getClientData();

            this.logger.debug("Value: " + event.currentTarget.value);
            if (event.currentTarget.value.length > 0) {
                if (this.smartSearchTimeout) {
                    this.logger.debug("Timeout cleared");
                    clearTimeout(this.smartSearchTimeout)
                }

                this.smartSearchTimeout = setTimeout(() => {
                    this._getSuggestionsForUi(suggestWidget);
                    this.currentItemIndex = -1;
                }, (data["min_search_time"] || 400));
            }
        });

        // shows/hides the suggest list if the widget gets focus
        suggestWidget.addEventListener('focus', () => {
            let acContainer = document.querySelector('.systema-ac-container');
            if (acContainer) {
                acContainer.classList.remove("hide");
                acContainer.classList.add("show");
            }
        });

        // shows/hides the suggest list
        window.addEventListener("click", (event) => {
            let search = document.querySelector('#search-input');
            if (search && !search.parentElement.contains(event.target)) {
                let ac = document.querySelector('.systema-ac-container');
                if (ac) {
                    ac.classList.remove("show");
                    ac.classList.add("hide");
                }
                let list = document.querySelector(".systema-ac-container");
                if (list) {
                    let activeItem = list.querySelector(".systema-ac-item-active");
                    if (activeItem) {
                        activeItem.classList.remove("systema-ac-item-active");
                    }
                    this.currentItemIndex = -1;
                }
            }
        });
    }

    _setupSuggestions() {

        if (this.SuggestMode != "None") {
            return;
        }

        let config = this._getClientData();

        // Search Widget for API Use
        let suggestWidgetName = config.suggest_widget_selector;
        if (suggestWidgetName && suggestWidgetName != "") {
            this._setupSuggestApi(suggestWidgetName, config);
        }

        // Search UI Placeholder
        let suggestUiWidgetName = config.suggest_ui_widget_selector;
        if (suggestUiWidgetName && suggestUiWidgetName != "") {
            this._setupSuggestUi(suggestUiWidgetName, config)
        }
    }

    _ensureSession(client) {
        let now = new Date();
        if (!client.last_activity_dt || new Date(client.last_activity_dt).dateDiff(now, "m") > (client.session_lifetime || 20)) {
            if (!client.last_activity_dt) {
                this.logger.debug(`No previous session activity`);
            } else {
                this.logger.debug(`No session activity for ${new Date(client.last_activity_dt).dateDiff(now, "m")}m (${client.session_lifetime || 20})`);
            }
            client.sid = this._generateSID();
            client.sequence = 0;
        }
        client.last_activity_dt = now.toISOString();
    }

    _getClientData() {
        return JSON.parse(localStorage.getItem(this.storageKey));
    }

    _getPayloadData() {
        let data = JSON.parse(localStorage.getItem(this.storageKey));
        this._ensureSession(data);
        data.sequence++;
        localStorage.setItem(this.storageKey, JSON.stringify(data));
        return data;
    }

    _getProductId() {
        let config = this._getClientData();
        if (!config) {
            return undefined;
        }

        var productId = null
        var productIdSelector = config["product_id_selector"];
        if (productIdSelector) {
            var element = document.querySelector(productIdSelector);
            if (element) {
                var productIdAttribute = config["product_id_attribute"];
                if (productIdAttribute) {
                    productId = element.getAttribute(productIdAttribute);
                } else {
                    productId = element.textContent;
                }
            }
        }

        return productId;
    }

    async _generateFID() {
        const fidObj = {}
        return await (async () => {
          // Get the visitor identifier when you need it.
          const fp = await this.fpPromise
          const result = await fp.get()
          const { canvas, ...components } = result.components
          if (result.visitorId) {
            const rawFingerprint = FingerprintJS.hashComponents(components)
            fidObj.fingerprintfromjs = rawFingerprint
            return fetch("https://www.cloudflare.com/cdn-cgi/trace").then(async res => {
                const traceInfo = await res.text()
                const ip = traceInfo.split('\n')[2].split('=')[1].trim()
                fidObj.ipaddress = ip
                components.ipaddress = {value: ip}
                fidObj.fid = `fph${FingerprintJS.hashComponents(components)}`
                return fidObj
            }).catch(_ => {
                fidObj.fid = `fpr${rawFingerprint}`
                return fidObj
            })
          } else {
            // fallback to random number generator if fingerprintJS fails
            function randomDigit() {
                if (crypto && crypto.getRandomValues) {
                    var rands = new Uint8Array(1);
                    crypto.getRandomValues(rands);
                    return (rands[0] % 16).toString(16);
                } else {
                    return ((Math.random() * 16) | 0).toString(16);
                }
            }
            var crypto = window.crypto || window.msCrypto;
            fidObj.fid = 'xxxxxxxx-xxxx-4xxx-8xxx-xxxxxxxxxxxx'.replace(/x/g, randomDigit);
            return fidObj
          }
        })()
    }

    _generateSID() {
        // https://stackoverflow.com/a/52171480
        // https://creativecommons.org/licenses/by-sa/4.0/

        let input = localStorage.getItem('systema-fid');
        let seed = Date.now();
        let h1 = 0xdeadbeef ^ seed, h2 = 0x41c6ce57 ^ seed;
        for (let i = 0, ch; i < input.length; i++) {
            ch = input.charCodeAt(i);
            h1 = Math.imul(h1 ^ ch, 2654435761);
        }
        h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909);
        let final = (h1 >>> 0).toString(16).padStart(8, 0).toUpperCase();
        return `${seed.toString().slice(0, 5)}-${seed.toString().slice(5, 10)}-${final.slice(0, 4)}-${final.slice(4, 8)}`;
    }

    _getSuggestionsForUi(searchWidget) {
        let queryText = searchWidget.value.trim();
        if (queryText === this.prevSearchTerm) {
            return;
        }

        this.prevSearchTerm = queryText;
        let config = this._getClientData();
        if (!config) {
            window.dispatchEvent(new CustomEvent("SystemaSearchResultError", { "detail": { code: 0, message: `Invalid Setup: No Configuration` } }));
        }

        if (queryText.length < (config["min_search_len"] || 3)) {
            let details = new Error("Min query length required " + String(config["min_search_len"] + ". Current length " + String(queryText.length)))
            window.dispatchEvent(new CustomEvent("SystemaSearchResultError", { "detail": { code: 100, message: `Invalid input: ${details}` } }));
            return;
        }
        let template = document.querySelector(config.suggest_ui_widget_selector).getAttribute("data-systema-suggest-placeholder");
        let payload = {
            "environment": config["environment"],
            "query": queryText,
            "template": template,
            "size": 5,
            "user_id": {
                "sid": this.getSID(),
                "fid": this.getFID(),
                "uid": this.getUID()
            }
        }
        let display = searchWidget.getAttribute("data-systema-display");
        if (display && display.length > 0) {
            payload.display = display.split(",");
        }

        fetch(`${config["search_base_url"]}/v1/suggest`, {
            method: "POST",
            headers: { "Content-Type": "text/plain" },
            body: JSON.stringify(payload)
        }).then(async (response) => {
            let result = await response.text();
            if (response.status != 200) {
                window.dispatchEvent(new CustomEvent("SystemaSearchResultError", { "detail": { code: response.status, message: `Invalid response: ${response.statusText}` } }));
            } else {
                this.logger.debug("Response from smart search " + result);
                this.currentItemIndex = -1;

                let mainContainer = document.querySelector(".systema-ac");
                let container = mainContainer.querySelector(".systema-ac-container");

                if (container) {
                    container.remove();
                }

                mainContainer.insertAdjacentHTML('beforeend', result);
                container = mainContainer.querySelector(".systema-ac-container");

                let dymItems = mainContainer.querySelectorAll(".systema-ac-item-dym");
                for (let item of dymItems) {
                    item.addEventListener('click', () => {
                        let value = item.querySelector(".systema-ac-item-title")
                        searchWidget.value = value.textContent;
                        searchWidget.dispatchEvent(new KeyboardEvent('keydown', { 'key': value.textContent.at(-1) }));
                    });
                }
                let pb = document.createElement("span");
                pb.classList.add("systema-ac-powered");
                pb.innerText = "Powered by ";
                container.appendChild(pb);

                let pbl = document.createElement("a");
                pbl.classList.add("systema-ac-powered-link");
                pbl.innerText = "Systema*";
                pbl.setAttribute("href", "https://systema.ai");
                pb.appendChild(pbl);
            }
        }).catch((error) => {
            window.dispatchEvent(new CustomEvent("SystemaSearchResultError", { "detail": { code: 0, message: `Unknown Error: ${error.message}` } }));
            this.logger.debug(`Search Suggest - Unknown Error: ${error.message}`);
        });
    }

    _getSuggestionsForApi(searchWidget) {
        let queryText = searchWidget.value.trim();
        if (queryText === this.prevSearchTerm) {
            return;
        }

        this.prevSearchTerm = queryText;
        let config = this._getClientData();

        if (queryText.length < (config["min_search_len"] || 3)) {
            let details = new Error("Min query length required " + String(config["min_search_len"] + ". Current length " + String(queryText.length)))
            window.dispatchEvent(new CustomEvent("SystemaSearchResultError", { "detail": { code: 100, message: `Invalid input: ${details}` } }));
            return;
        }

        let payload = {
            "environment": config["environment"],
            "query": queryText,
            "user_id": {
                "sid": this.getSID(),
                "fid": this.getFID(),
                "uid": this.getUID()
            }
        }
        let display = searchWidget.getAttribute("data-systema-display");
        if (display && display.length > 0) {
            payload.display = display.split(",");
        }

        fetch(`${config["search_base_url"]}/v1/suggest`, {
            method: "POST",
            headers: { "Content-Type": "text/plain" },
            body: JSON.stringify(payload)
        }).then(async (response) => {
            let result = await response.json();
            if (response.status != 200) {
                window.dispatchEvent(new CustomEvent("SystemaSuggestResultError", { "detail": { code: response.status, message: `Invalid response: ${response.statusText}` } }));
            } else {
                this.logger.debug("Response from smart search " + result);
                window.dispatchEvent(new CustomEvent("SystemaSearchResult", { "detail": result }));
            }
        }).catch(function (error) {
            window.dispatchEvent(new CustomEvent("SystemaSuggestResultError", { "detail": { code: 0, message: `Unknown Error: ${error.message}` } }));
        });
    }

    async _renderCarousel(carousel) {
        let carousel_id = carousel.getAttribute("data-systema-placeholder");
        let apiData = this.getApiData();

        let payload = {
            environment: apiData.environment,
            user_id: apiData.user_id
        }

        // Find the ID
        let idSelector = carousel.getAttribute("data-systema-id-selector");
        let categorySelector = carousel.getAttribute("data-systema-category-selector");
        let categoryValue = carousel.getAttribute("data-systema-category-value");
        if (idSelector) {
            try {
                payload.id = this._processSelector(idSelector);
            } catch (error) {
                this.logger.error(error.message);
                return;
            }
        }
        else if (categorySelector) {
            try {
                let categorySeparator = carousel.getAttribute("data-systema-category-separator");
                try {
                    payload.category = this._processSelectorArray(categorySelector, categorySeparator);
                } catch (error) {
                    this.logger.error(error.message);
                    return;
                }
            } catch (error) {
                this.logger.error(error.message);
                return;
            }
        } else if (categoryValue) {
            payload.category = JSON.parse(categoryValue);
        }

        // Add any parameters specified
        let carouselAttributes = carousel.getAttributeNames().filter(a => a.startsWith("data-systema-param-"));
        for (let attribute of carouselAttributes) {
            let name = attribute.replace("data-systema-param-", "");
            let value;
            switch (name) {
                case "exclusion":
                    value = JSON.parse(carousel.getAttribute(attribute));
                    // Ensure any List types are actually Lists
                    let valueTypes = ["id", "categroy", "tags"];
                    for (const valueType of valueTypes) {
                        if (value[valueType] && !Array.isArray(value[valueType])) {
                            value[valueType] = [value[valueType]];
                        }
                    }

                    break;
                case "pagination_timestamp":
                case "size":
                case "start":
                case "facet_size":
                    value = parseInt(carousel.getAttribute(attribute));
                    break;
                default:
                    value = carousel.getAttribute(attribute);
                    break;
            }
            payload[name] = value
        }

        // This should be migrated to data-systema-param-filter
        let filterText = carousel.getAttribute('data-systema-filter');
        if (filterText) {
            try {
                let allFilters = JSON.parse(filterText);
                for (let filterName of Object.keys(allFilters)) {
                    let filter = allFilters[filterName];
                    // ^ signifies its NOT a string literal and should be evaluated
                    // filter.startsWith is a test to not process arrays. Can only substitute string values not within sub object at atm
                    if (filter && filter.startsWith && filter.startsWith("^")) {
                        filter = filter.replace("^", ""); // remove the first instance of "^"
                        if (filter[0] === '$') { // $ signifies a CSS $elector
                            filter = filter.replace("$", ""); // remove the first instance of "$"
                            try {
                                allFilters[filterName] = this._processSelectorArray(filter);
                            } catch (error) {
                                this.logger.error(error.message);
                                return;
                            }
                            break;
                        } else {
                            this.logger.error("Unknown Instructor Character");
                            break;
                        }
                    }
                }
                payload.filter = allFilters;
            } catch (error) {
                this.logger.error(error.message);
            }

        }

        // And go!
        let response = await fetch(`${apiData.endpoints.recommend}/v1/carousel/${carousel_id}`, {
            method: "POST",
            headers: { "Content-Type": "text/plain" },
            body: JSON.stringify(payload)
        });

        let body = await response.text();

        // We only set the page size if its not already been set
        let pagesize = carousel.getAttribute("data-systema-page-size");
        if (!pagesize) {
            let psValue = response.headers.get("x-systema-page-size");
            if (psValue) {
                carousel.setAttribute("data-systema-page-size", psValue);
            }
        }

        // We only set the timestap if its not already been set
        let timestamp = carousel.getAttribute("data-systema-param-timestamp");
        if (!timestamp) {
            let tsValue = response.headers.get("x-systema-page-timestamp");
            if (tsValue) {
                carousel.setAttribute("data-systema-param-pagination_timestamp", tsValue);
            }
        }
        carousel.innerHTML = body;
    }

    _processSelector(selectorText) {
        let attributeName = undefined;
        if (selectorText.includes("@")) {
            let position = selectorText.indexOf("@");
            attributeName = selectorText.slice(position + 1);
            selectorText = selectorText.slice(0, position);
        }
        let selectorElement = document.querySelector(selectorText);

        if (selectorElement) {
            if (attributeName) {
                return selectorElement.getAttribute(attributeName);
            } else {
                return selectorElement.textContent;
            }
        } else {
            throw new Error("Selector returned no element");
        }
    }

    _processSelectorArray(selectorText, separator) {
        let attributeName = undefined;
        if (selectorText.includes("@")) {
            let position = selectorText.indexOf("@");
            attributeName = selectorText.slice(position + 1);
            selectorText = selectorText.slice(0, position);
        }
        let selectorElements = document.querySelectorAll(selectorText);
        let elements = [];
        if (attributeName) {
            for (let element of selectorElements) {
                elements.push(element.getAttribute(attributeName));
            }
        }
        else {
            // from an array or list, "<li>Home</li><li>Clothing</li><li>Tops</li>"
            elements = Array.from(selectorElements).map(a => a.textContent.trim());
        }

        if (elements.length == 0) {
            // Invalid, should return at least 1 item
            throw new Error("The Selector specified returned no value.");
        }
        if (separator) {
            // Category from textual field with a separator, "Clothing > Tops"
            if (elements.length > 1) {
                throw new Error("A Category Separator (data-systema-category-separator) was specified but more than 1 item was returned.");
            }
            elements = elements[0].split(separator).map(c => c.trim());
        }

        return elements
    }

    _sendMessage(type, additional_data) {
        this.logger.debug("Sending Message");
        let data = this._getPayloadData();

        this.logger.debug("Building Payload");
        let now = new Date();
        var message = {
            ClientId: data.client_key,
            Environment: data.environment,
            EventDate: {
                LocalDate: now.toLocalISOString(),
                TimeZone: now.toTimezoneNameString(),
                UtcDate: now.toISOString()
            },
            Fingerprint: this.getFID(),
            FingerprintFromJs: data.fingerprintfromjs,
            IpAddress: data.ipaddress,
            Referer: document.referrer,
            Sequence: data.sequence,
            SessionId: data.sid,
            Type: type,
            Url: window.location.href,
            UserAgent: navigator.userAgent,
            UserName: this.getUID() || "",
            Version: this.version
        }

        if (additional_data && additional_data.length) {
            for (const item of additional_data) {
                message[item.name] = item.value;
            }
        }

        this.productId = this._getProductId();
        if (this.productId) {
            message.ProductId = this.productId;
        }

        let payload = JSON.stringify(message)
        navigator.sendBeacon(`${data.tracker_base_url}/v1/tracker`, new Blob([payload], { type: 'text/plain' }));
        this.logger.info(`Sent Message '${type}'`);
        this.logger.debug(payload);
    }

    _setClientData(config) {

        let client = localStorage.getItem(this.storageKey) || undefined;
        if (!client) {
            client = config;
        } else {
            client = JSON.parse(client);
            // Update stored values with any that have been passed
            for (let [key, value] of Object.entries(config)) {
                client[key] = value;
            }
        }

        this._ensureSession(client);
        localStorage.setItem(this.storageKey, JSON.stringify(client));
        return client;
    }

    _isInViewport(element) {
        var bounding = element.getBoundingClientRect();
        return (
            bounding.top >= 0 &&
            bounding.left >= 0 &&
            bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    }

    _moveActiveSuggestItem(moveCount) {
        let list = document.querySelector(".systema-ac-container");
        if (!list) {
            return;
        }

        let items = list.querySelectorAll(".systema-ac-item:not(.systema-ac-header)")
        let activeItem;
        if (this.currentItemIndex === -1) {
            this.currentItemIndex++;
            activeItem = items[0];
        } else {
            activeItem = items[this.currentItemIndex];
            activeItem.classList.remove("systema-ac-item-active");
            this.currentItemIndex = this.currentItemIndex + moveCount

            if (this.currentItemIndex >= items.length) {
                this.currentItemIndex = 0;
            }

            if (this.currentItemIndex < 0) {
                this.currentItemIndex = (items.length - 1);
            }
            activeItem = items[this.currentItemIndex];
        }
        activeItem.classList.add("systema-ac-item-active");
    }
}

class Logger {
    constructor() {
        this.level = {
            debug: 1,
            info: 2,
            warn: 3,
            error: 4,
            none: 999
        }
        this.log_level = this.level.none;
    }

    debug(message) {
        this._log(this.level.debug, message);
    }

    info(message) {
        this._log(this.level.info, message);
    }

    warn(message) {
        this._log(this.level.warn, message);
    }

    error(message) {
        this._log(this.level.error, message);
    }

    _log(log_level, message) {
        if (this.log_level <= log_level) {
            console.log("%cSystema: " + new Date().toISOString() + "%c - " + message, "color: orange; background-color: black; padding: 0px 5px 0px 5px", "color: black ; background-color: white;");
        }
    }
}

Date.prototype.dateDiff = function (date2, type) {
    if (!type) {
        throw new Error("Type must be specified");
    }

    let multiplier;
    switch (type) {
        case "s":
            multiplier = 1000;
            break;
        case "m":
            multiplier = (1000 * 60);
            break;
        case "h":
            multiplier = (1000 * 60 * 60);
            break;
        case "d":
            multiplier = (1000 * 60 * 60 * 24);
            break;
        default:
            multiplier = 1;
            break;
    }
    return Math.abs(Math.floor((date2 - this) / multiplier));
}

Date.prototype.toLocalISOString = function () {
    var tzo = -this.getTimezoneOffset(),
        dif = tzo >= 0 ? '+' : '-';
    return this.getFullYear() +
        '-' + (this.getMonth() + 1).toString().padStart(2, '0') +
        '-' + this.getDate().toString().padStart(2, '0') +
        'T' + this.getHours().toString().padStart(2, '0') +
        ':' + this.getMinutes().toString().padStart(2, '0') +
        ':' + this.getSeconds().toString().padStart(2, '0') +
        dif + (tzo / 60).toString().padStart(2, '0') +
        ':' + (tzo % 60).toString().padStart(2, '0');
}

Date.prototype.toTimezoneNameString = function () {
    var tzo = -this.getTimezoneOffset();
    var dif = tzo >= 0 ? '+' : '-';
    var bits = this.toLocaleTimeString('en-us', { timeZoneName: 'long', hour12: false }).split(' ');
    bits.shift();
    return bits.join(" ") + " (" + dif + (tzo / 60).toString().padStart(2, '0') + ":" + Math.floor((tzo % 60)).toString().padStart(2, '0') + ")";
}

window.Systema = new Systema();

let attributes = Array.from(document.currentScript.attributes).filter(a => a.name.startsWith("data-"));
if (attributes && attributes.length > 0) {
    let config = {}
    for (const attribute of attributes) {
        if (attribute.name.startsWith("data-")) {
            let name = attribute.name.replace('data-', '').replace(/-/g, '_');
            config[name] = attribute.value;
        }
    }

    window.Systema.run(config);
}
